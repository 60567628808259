<template>
  <div id="inventory-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('products.inventory') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-spacer />
        <v-col
          cols="12"
          md="2"
          sm="12"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="onExport"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-fab-transition v-if="hasPermission(50)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onNew"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card> -->

    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          cols="12"
          md="2"
          sm="12"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="onExport"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>

        <v-col
          v-if="hasPermission(50)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onNew"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="dataListTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            <div
              class="d-flex flex-column ms-3"
              style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
            >
              <router-link
                :to="{ id: 'views-players-group-form', params: { id: item.id, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.id }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.fullname`]="{item}">
          <div class="d-flex align-center">
            <div
              class="d-flex flex-column ms-3"
              style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
            >
              <router-link
                :to="{ fullname: 'views-players-group-form', params: { id: item.description, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullname }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.adjustment_date`]="{item}">
          <span class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
            {{ formatStdDate(item.adjustment_date, 'datelist', configObjFacility.is12Hour, $i18n.locale) }}
          </span>
          <!-- <div class="d-flex align-center">
            <div
              class="d-flex flex-column ms-3"
              style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
            >
              <router-link
                :to="{ adjustment_date: 'views-players-group-form', params: { id: item.description, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.adjustment_date }}
              </router-link>
            </div>
          </div> -->
        </template>

        <template #[`item.adjusted_products`]="{item}">
          {{ item.products.length }}
        </template>

        <template #[`item.adjustment_cost`]="{item}">
          {{ formatCurrency(item.adjustment_cost) }}
        </template>

        <template #[`item.actions`]="{item}">
          <div>
            <v-tooltip
              v-if="hasPermission(49)"
              bottom
            >
              <template #activator="{ on: tooltip, attrs }">
                <v-icon
                  size="20"
                  class="me-2"
                  color="info"
                  v-bind="attrs"
                  v-on="{ ...tooltip }"
                  @click="onShow(item)"
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </template>
              <span>Details</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="modalOpen"
      max-width="800"
    >
      <v-card class="user-card-detail pa-sm-10 pa-3">
        <v-card-title class="user-edit-title justify-center text-h5">
          New Inventory
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form
            ref="form"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="nameCategory"
                  :disabled="isFieldsDisabled"
                  outlined
                  dense
                  label="Name"
                  :error-messages="errorMessages.name"
                  placeholder="Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-select
                  v-model="statusCategory"
                  :disabled="isFieldsDisabled"
                  dense
                  outlined
                  :label="t('status.status')"
                  :items="statusOptions"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex align-center"
                >
                  <v-textarea
                    v-model="descriptionCategory"
                    :disabled="isFieldsDisabled"
                    dense
                    outlined
                    label="Description"
                    placeholder="Description"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    class="me-3"
                    :disabled="isFieldsDisabled"
                    @click="saveAction"
                  >
                    {{ t('tooltip.save') }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="modalOpen =!modalOpen"
                  >
                    {{ t('tooltip.cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`Inventory ${computedNow}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <inventory-report
          :data-params="computedInventoryData"
        ></inventory-report>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiPrinter,
} from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import { info, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'

import VueHtml2pdf from 'vue-html2pdf'
import InventoryReport from '@/templates/reports/InventoryReport.vue'

import useInventoryList from './useInventoryList'

export default {
  components: {
    VueHtml2pdf,
    InventoryReport,
  },
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const { configObjFacility } = useSelectOptions()
    const {
      dataListTable,
      computedTableColumns,
      loading,
      configFacility,
      userData,
      options,
      filters,
      searchQuery,

      getDataBySearch,
      fetchProductsCategories,
      fetchEditInventory,
      resolveStatusVariant,
      resolveStatusText,
      resolveFacilitiesText,
      hasPermission,
      setFilters,
      setOptions,
    } = useInventoryList()

    const modalOpen = ref(false)
    const isFieldsDisabled = ref(false)
    const onEditAction = ref(false)
    const errorMessages = ref([])
    const nameCategory = ref('')
    const idCategory = ref(null)
    const descriptionCategory = ref('')
    const statusCategory = ref({ text: 'Active', value: 'A' })
    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const html2Pdf = ref(null)
    const inventoryData = ref([])
    const computedInventoryData = computed(() => inventoryData.value)
    const now = ref(new Date().toISOString())
    const computedNow = computed(() => `${now.value.substring(0, 10)} ${now.value.substring(11, 16)}`)

    const onNew = () => {
      router.push({
        name: 'views-inventory-form',
        params: {
          option: 1,
          pageParams: options.value,
          backTo: 'views-inventory-list',
        },
      })
      isFieldsDisabled.value = false
      onEditAction.value = true
    }

    const saveAction = async () => {
      /* if (onEditAction.value === true) {
        await postProductCategory({ facility_id: configFacility.value, name: nameCategory.value, description: descriptionCategory.value, status: statusCategory.value.value })
      } else {
        await putProductCategory({ id: idCategory.value, facility_id: configFacility.value, name: nameCategory.value, description: descriptionCategory.value, status: statusCategory.value.value })
      } */
      nameCategory.value = ''
      descriptionCategory.value = ''
      statusCategory.value = { text: 'Active', value: 'A' }
      await fetchProductsCategories()
      modalOpen.value = false
    }

    const onShow = item => {
      router.push({
        name: 'views-inventory-form',
        params: {
          option: 2,
          id: item.id,
          pageParams: options.value,
          backTo: 'views-inventory-list',
        },
      })
      isFieldsDisabled.value = true
      onEditAction.value = false
    }

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
      inventoryData.value = []
    }

    const onExport = async () => {
      const data = await fetchEditInventory(configFacility.value)
      inventoryData.value = data
      setTimeout(() => {
        generateReport()
      }, 1000)
    }

    const onPrint = item => {
      inventoryData.value = item
      setTimeout(() => {
        generateReport()
      }, 1000)
    }

    onMounted(async () => {
      await getDataBySearch(300)
    })

    return {
      // data
      html2Pdf,
      inventoryData,
      configFacility,
      statusOptions,

      // computed
      computedInventoryData,
      computedNow,

      // methods
      generateReport,
      beforeDownload,
      hasDownloaded,

      dataListTable,
      computedTableColumns,
      loading,
      options,
      filters,
      searchQuery,
      modalOpen,
      errorMessages,
      nameCategory,
      statusCategory,
      descriptionCategory,
      saveAction,
      isFieldsDisabled,
      onEditAction,
      onNew,
      idCategory,
      userData,
      configObjFacility,

      onShow,
      onPrint,
      onExport,
      resolveStatusVariant,
      resolveStatusText,
      resolveFacilitiesText,
      hasPermission,
      setFilters,
      setOptions,
      formatCurrency,
      formatStdDate,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiPrinter,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
